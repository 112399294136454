<template>
  <section class="text-block part">
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-8">
          <div class="text" v-html="payload.text" />
          <div class="badges">
            <div
              v-for="badge of payload.badges"
              :key="badge.id"
              class="badge"
            >
              <div class="badge-box">
                {{ badge.nummer }}
              </div>
              {{ badge.title }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: { payload: { type: Object, default: () => ({}) } },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixin.scss";
@import "@/assets/styles/_type.scss";

:deep(h2),
:deep(h3) {
  @include responsive('margin-bottom', px(25), px(32));
}
:deep(h3) {
  @include responsive('margin-top', px(32), px(60));
}

.text {
  @include responsive('margin-top', px(49), px(139));

  &:first-child {
    margin-top: 0;
  }

  :deep(p),
  :deep(a),
  :deep(li) {
    @apply text-ssm;
    line-height: px(22);

    @screen md {
      @apply text-base;
      line-height: px(36);
    }
  }
  :deep(h3) {
    @apply text-m;
    line-height: px(28);

    @screen md {
      @apply text-lg;
      line-height: px(42);
    }
  }
}

.badges {
  @apply text-primary;
  @extend .h3;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-row-gap: 1rem;

  .badge {
    display: grid;
    grid-template-columns: min-content 1fr;
    width: fit-content;
    align-items: center;

    @include responsive('grid-column-gap', px(16), px(32));

    .badge-box {
      @apply rounded-full;
      @apply border-2;

      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 1em;
      margin-right: auto;
      white-space: nowrap;
      width: clamp(#{px(38)}, 5vw, #{px(60)});
      height: clamp(#{px(38)}, 5vw, #{px(60)});

      @include responsive('padding', px(10), px(12))
    }
  }

  @include responsive('margin-top', px(42), px(170));
}
</style>
